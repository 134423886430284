<template>
    <div>
        <div class="mb-4" v-for="item in stepContentList" :key="item.text">
            <p class="my-4" v-html="$t(`faqTab.gettingStarted.paypal.${item.text}`)" />
            <img class="w-100" :src="item.image" :alt="$t(`faqTab.gettingStarted.paypal.${item.text}`)">
        </div>
        <p v-html="$t('faqTab.gettingStarted.paypal.detailed')" />
        <a class="text-break" :href="helpLink1" target="_blank">{{ helpLink1 }}</a>
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.bankAccount')" />
        <p class="mb-4" v-html="$t('faqTab.gettingStarted.paypal.option')" />
        <img class="w-100" src="../assets/step2.4.png" :alt="$t('faqTab.gettingStarted.paypal.option')">
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.find')" />
        <img class="w-100" src="../assets/step2.5.png" :alt="$t('faqTab.gettingStarted.paypal.find')">
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.afterChoosing')" />
        <a class="text-break" :href="helpLink2" target="_blank">{{ helpLink2 }}</a>
        <img class="w-100 mt-4" src="../assets/step2.6.png" :alt="$t('faqTab.gettingStarted.paypal.afterChoosing')">
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.detailedVideo')" />
        <a class="text-break" :href="helpLink3" target="_blank">{{ helpLink3 }}</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            helpLink1: "https://www.paypal.com/us/smarthelp/article/how-do-i-link-a-debit-or-credit-card-to-my-paypal-account-faq826debit-or-credit-card-to-my-paypal-account-faq826",
            helpLink2: "https://www.paypal.com/us/smarthelp/article/how-do-i-confirm-my-bank-account-faq3045confirm-my-bank-account-faq3045",
            helpLink3: "https://www.paypal.com/us/smarthelp/article/how-do-i-link-a-bank-account-to-my-paypal-account-faq686account-to-my-paypal-account-faq686  ",
            stepContentList: [
                {
                    image: require("../assets/step2.1.png"),
                    text: "bottom"
                },
                {
                    image: require("../assets/step2.2.png"),
                    text: "linking"
                }, 
                {
                    image: require("../assets/step2.3.png"),
                    text: "fill"
                }
            ]
        }
    }
}
</script>